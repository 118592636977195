import React from 'react';
import Cover from '../components/pagecover/Cover';
import {products} from "../components/menu/data";

const Products = () => {
    return (
        <div id="aboutPage">
            <Cover
                backgroundImage="https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                title="Our Products"
                description="Learn more about our journey and values"
            />

            <div className="product-grid max-w-[1200px] mx-auto grid lg:grid-cols-4 gap-4 py-[80px]">
                {products.map((p) => (
                    <div key={p.id} className="product-card p-4 border rounded">
                        <a href={p.url}>
                            <img src={p.coverImage} alt={p.name} className="w-full h-auto" />
                        </a>
                        <h2 className="text-xl font-semibold">{p.name}</h2>
                        <p>{p.shortDescription}</p>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Products;