import React from 'react';
import Cover from '../components/pagecover/Cover';

const Contacts = () => {
    return (
        <div id="aboutPage">
            <Cover
                backgroundImage="https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                title="Contact Us"
                description="Learn more about our journey and values"
            />

        </div>
    );
};

export default Contacts;