// MegaMenu.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { products } from './data';

const MegaMenu = () => {
    return (
        <div className="mega-menu max-w-[1200px] mx-auto">
            <ul className="product-list grid lg:grid-cols-4 gap-2 justify-between align-center">
                {products.map((product) => (
                    <li key={product.id} className="py-1">
                        <Link to={product.url} className="text-sm text-gray-700 data-[focus]:text-gray-900">
                            <img src={product.coverImage} alt={product.name} className="" />
                            <span className="product-name">{product.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

MegaMenu.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default MegaMenu;
