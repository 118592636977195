import React from "react";
import './footer.css';
import SocialMediaIcons from '../socialmediaicons/SocialMediaIcons';

const Footer = () => {
    return (
        <section id="footer" className="sm:px-[20px]">
            <footer className="max-w-[1240px] mx-auto flex justify-between content-between gap-[30px] content-center justify-center items-center px-[20px] py-[80px] sm:px-[20px]">
                <ul className="info_links flex justify-between content-between gap-[30px]">
                    <li><a href="#" className="roboto-light">Privacy</a></li>
                    <li><a href="#" className="roboto-light">Accessibility</a></li>
                    <li><a href="#" className="roboto-light">terms</a></li>
                    <li><a href="#" className="roboto-light">Return</a></li>
                </ul>

                <SocialMediaIcons />
            </footer>

            <div className="copyrights flex-1">
                <p className="font-bold">© 2024, eveneiusa.</p>
            </div>

        </section>
    )
}

export default Footer;