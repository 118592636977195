import React from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { products } from '../components/menu/data'; // Ensure this path is correct

const defaultImage = 'images/default.jpg'; // Set your default image path

const ProductPage = () => {
    const { id } = useParams();
    const product = products.find((p) => p.id === parseInt(id, 10));

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className="product-page">
            {/* Cover Section */}
            <div className="cover" style={{ backgroundImage: `url(${product.coverImage || defaultImage})` }}>
                <div className="cover-content text-white p-8">
                    <h1 className="text-4xl font-bold mb-4">{product.name}</h1>
                    <p className="mb-4">{product.description}</p>
                </div>
            </div>

            {/* Product Details Section */}
            <div className="product-details max-w-[1240px] mx-auto grid grid-cols-2 sm:grid-col-1 gap-[40px] py-[80px] px-[20px]">
                {/* Swiper Slider */}
                <div className="w-full lg:w-1/2">
                    <Swiper spaceBetween={10} slidesPerView={1} loop>
                        {(product.images.length > 0 ? product.images : [defaultImage]).map((imgSrc, index) => (
                            <SwiperSlide key={index}>
                                <img src={imgSrc} alt={`Slide ${index}`} className="w-full h-auto" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Product Information */}
                <div className="w-full lg:w-1/2">
                    <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
                    <p>{product.longDescription}</p>
                    <button className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-md">
                        Contact Us
                    </button>
                </div>
            </div>

            {/* Related Products Grid */}
            <div className="related-products max-w-[1200px] mx-auto py-8">
                <h2 className="text-3xl font-bold mb-4">Related Products</h2>
                <div className="grid lg:grid-cols-4 gap-4">
                    {products.filter(p => p.id !== product.id).slice(0, 4).map((relatedProduct) => (
                        <div key={relatedProduct.id} className="product-card p-4 border rounded">
                            <img src={relatedProduct.coverImage || defaultImage} alt={relatedProduct.name} className="w-full h-auto" />
                            <h3 className="text-xl font-semibold">{relatedProduct.name}</h3>
                            <p>{relatedProduct.shortDescription}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
