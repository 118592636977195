import React, { useState, useEffect } from 'react';
import Drawer from "../sidemenu/Drawer";
import Menu from "../menu/Menu";
import SocialMediaIcons from "../socialmediaicons/SocialMediaIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../header/header.css';

export default function Header({ setIsOpen }) {
    const [openLeft, setOpenLeft] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                setShowHeader(false); // Scrolling down
            } else {
                setShowHeader(true); // Scrolling up
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            <header className={`header ${showHeader ? 'visible' : 'hidden'}`}>
                <div className="lg:flex hidden max-w-[1200px] mx-auto h-20 flex justify-between md:p-4">
                    <div className="flex items-center justify-center cursor-pointer gap-x-[20px]">
                        <a href="/">
                            <img src="/images/evenei-logo.svg" alt="Eveneiusa Logo" className="logo" />
                        </a>
                        <FontAwesomeIcon icon={faBars} onClick={() => setOpenLeft(!openLeft)} />
                    </div>
                    <Menu />
                    <SocialMediaIcons />
                </div>

                <div className="lg:hidden flex justify-between items-center p-4">
                    <a href="/">
                        <img src="/images/evenei-logo.svg" alt="Eveneiusa Logo" className="logo" />
                    </a>
                    <FontAwesomeIcon icon={faBars} onClick={() => setOpenLeft(!openLeft)} />
                </div>
            </header>
            <div className="sm:flex items-center justify-center flex-col">
                <Drawer open={openLeft} side="left" setOpen={setOpenLeft} />
            </div>
        </>
    );
}
