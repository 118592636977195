import React from 'react';
import Cover from '../components/pagecover/Cover';

const WeHelp = () => {
    return (
        <div id="aboutPage">
            <Cover
                backgroundImage="https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                title="We Can Help"
                description="Learn more about our journey and values"
            />

        </div>
    );
};

export default WeHelp;