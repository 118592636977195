import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/loader/Loader';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contacts from './pages/Contacts';
import WeHelp from './pages/Help';
import ProductPage from './pages/ProductPage'; // Import the ProductPage component

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Adjust the delay as needed
    }, []);

    return (
        <Router>
            {loading ? <Loader /> : <MainContent />}
        </Router>
    );
}

const MainContent = () => (
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/help" element={<WeHelp />} />
            <Route path="/product/:id" element={<ProductPage />} /> {/* Route for product pages */}
        </Routes>
        <Footer />
    </div>
);

export default App;
