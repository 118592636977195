
export const featuresImages = [
    { id: 1, src: 'images/pic-01.png', alt: 'Description of image 1' },
    { id: 2, src: 'images/pic-02.png', alt: 'Description of image 2' },
    { id: 3, src: 'images/pic-03.png', alt: 'Description of image 3' },
    { id: 4, src: 'images/pic-04.png', alt: 'Description of image 1' },
    { id: 5, src: 'images/pic-05.png', alt: 'Description of image 2' },
    { id: 6, src: 'images/pic-06.png', alt: 'Description of image 3' },
    // Add more images as needed
];

export default featuresImages;